






























import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { IUser } from "@/models/IUser";

@Component
export default class UsersComponent extends Vue {
  users: IUser[] = [];

  openUser = (id: string) => {
    this.$router.push("/users/" + id);
  }

  public async mounted() {
    await adminService.getAllUsers().then((response) => {
      this.users = response;
    });
  }
}
